import { IDatePickerStrings } from "@fluentui/react/lib/components/DatePicker/DatePicker.types"

export interface IInputRow {
    id?: string
    name?: string
    header?:any[]
    row?:any[]

  }
  export interface IInputTable {
    name: string
    rows:any[][]
  }
  export interface IInputSmeta {
    filename:string
    p: any[][]
    0: any[][]
    1: any[][]
    2: any[][]
    3: any[][]
    4: any[][]
    vr: any[][]
    i: any[][]
    k: any[][]
    lz: any[][]
    wl: any[][]
  }
export const props_row_enum={
    ProgramVersion:0,
    Generator:1,
    LocNum:2,
    Description:3,
    RegionName:4,
    RegionID:5,
    Zone01Name:6,
    Zone01ID:7,
    AdmRegionCode:8,
    Overhd_BaseName:9,
    Overhd_RegNumber:10,
    Overhd_RegDate:11,
    Overhd_OrderDetails:12,
    Profit_BaseName:13,
    Profit_RegNumber:14,
    Profit_RegDate:15,
    Profit_OrderDetails:16,
    CatFile:17,
    NrspFile:18,
    IndexesMode:19,
    IndexesLinkMode:20,
    CommonNK_CommonK:21,
    CommonNK_MethodKB:22,
    CommonNK_MethodKI:23,
    CommonNK_MethodKR:24,
    CommonNK_ActiveItems:25,
    CommonPK_CommonK:26,
    CommonPK_MethodKB:27,
    CommonPK_MethodKI:28,
    CommonPK_MethodKR:29,
    CommonPK_ActiveItems:30,
    ComposeFIO:31,
    VerifyFIO:32,
    RegNumber:33,
    RegDate:34,
    OrderDetails:35,
    BaseName:36,
    BaseType:37,
    Overhd_BaseType:38,
    Profit_BaseType:39,
    
} 
export const props_col_enum={
    Id:0,
    Type:1,
    Group:2,
    Caption:3,
    Value:4
} 
export const vrs_col_enum={
    Grop_Caption:0,
    Group_ID:1,
    Caption:2,
    ID:3,
    Category:4,
    NrCode:5,
    SpCode:6,
    Nacl:7,
    Plan:8,
    NaclCurr:9,
    PlanCurr:10,
    NaclMask:11,
    PlanMask:12,
    OsColumn:13,
    Row:14,
    NKB:15,
    PNB:16,
    NKI:17,
    PKI:18,
    NKR:19,
    PKR:20,
}
export const chapters_col_enum={
    Caption:0,
    Row:1,
} 
export const positions_col_enum={
    Chapter:0,
    Number:1,
    Code:2,
    Caption:3,
    Units:4,
    PriceLevel:5,
    DBComment:6,
    Vr2001:7,
    Quantity:8,
    Row:9,
    Options:10,
    IndexCode:11,
    BOZ:12,
    BEM:13,
    BZM:14,
    BMT:15,
    COZ:16,
    CEM:17,
    CZM:18,
    CMT:19,
    SlaveRow:20,
    AuxMatCode:21,
    AuxMat:22,
    NKB:23,
    PNB:24,
    NKI:25,
    PKI:26,
    NKR:27,
    PKR:28,
}
export const resources_col_enum={
    Type:0,
    Position:1,
    Caption:2,
    Code:3,
    Units:4,
    Quantity:5,
    WorkClass:6,
    PriceBaseValue:7,
    PriceBaseZM:8,
    PriceCurrValue:9,
    PriceCurrZM:10,
    TzmWorkClass:11,
    TzmQuantity:12,
    TzmPrice:13,
    Options:14,
    IndexCode:15,
    Attribs:16,
    Row:17,
    Mass:18
}
export const indexes_col_enum={
    Type:0,
    Caption:1,
    Code:2,
    OZ:3,
    EM:4,
    ZM:5,
    MT:6,
    Row:7
}
export const k_col_enum={
    Position:0,
    Caption:1,
    Code:2,
    Options:3,
    Value_OZ:4,
    Value_EM:5,
    Value_ZM:6,
    Value_MT:7,
    Level:8
}
export const wls_col_enum={
    Position:0,
    Caption:1,
}
export const lzs_col_enum={
    Glava:0,
    Caption:1,
    Value:2,
    Options:3,
    Level:4,
    Formula:5
}
export const trans_col_enum={
    Resource:0,
    Caption:1,
    Code:2,
    Units:3,
    PriceCurr:4,
    KC:5
}
export interface IDownloadFile {
    xml?: string
    filename?: string
  }

  export const RusDatePickerStrings:IDatePickerStrings={
   goToToday:'Сегодня',
   months:[
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
   ],
   shortMonths:[
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек'
   ],
   days:[
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
   ],
   shortDays:[
    'Вс',
    'Пн',
    'Вт',
    'Ср',
    'Чт',
    'Пт',
    'Сб',
    
   ]
}